import React from "react"
import LeadGeneratorLoadable from "./LeadGeneratorLoadable"
import Header from "../Header"

const LeadGeneratorFullscreen = ({ fixedPathname }) => {
  return (
    <>
      <Header
        // className={}
        type="boxed"
        minimal={true}
        fixed={false}
        initBgColor="white"
        initColor="light"
      />
      <div style={{minHeight: '80vh'}}>
        <LeadGeneratorLoadable leadGenerator="rating" setPath={fixedPathname} />
      </div>
    </>
  )
}

export default LeadGeneratorFullscreen
