import React, { useContext, useEffect } from "react"
import LeadGeneratorLoadable from "../../components/generator/LeadGeneratorLoadable"
import { ArrowDown, ArrowRight, Check, ChevronDown } from "react-feather"
import HelmetHead from "../../components/HelmetHead"
import TimeSchedule from "../../svgs/icons/duotone-icons/Code/Time-schedule.svg"
import Euro from "../../svgs/icons/duotone-icons/Shopping/Euro.svg"
import ClipboardCheck from "../../svgs/icons/duotone-icons/Communication/Clipboard-check.svg"
import Home from "../../svgs/icons/duotone-icons/Home/Home.svg"
import Wallet from "../../svgs/icons/duotone-icons/Shopping/Wallet.svg"
import CodeGithub from "../../svgs/icons/duotone-icons/Code/Github.svg"
import Accordion from "react-bootstrap/Accordion"
import { Link } from "gatsby"
import Curve1 from "../../svgs/shapes/curves/curve-1.svg"
const CTA = Loadable(() => import("../../components/CTA"))
import imgLeft from "../../images/custom/immorating_cta_links.png"
import imgRight from "../../images/custom/immorating_cta_rechts.png"
const CTA2 = Loadable(() => import("../../components/CTA2"))
const Newsletter = Loadable(() => import("../../components/Newsletter"))
import BackgroundSection from "../../components/BackgroundSection"
import StepImgNo from "../../components/StepImgNo"
import AccordionHeaderCustomScroll from "../../components/AccordionHeaderCustomScroll"
import smoothScroll from "../../components/smoothScroll"
import LayoutContext from "../../components/LayoutContext"
import Loadable from "@loadable/component"
import LeadGeneratorFullscreen from "../../components/generator/LeadGeneratorFullscreen"

const Immobilienbewertung = ({ location }) => {
  //define if lead-generator is used in page!
  const canonicalPathname = `/de/immobilienbewertung/`
  const fixedPathname = `/de/immobilienbewertung/`

  const [layout, setLayout] = useContext(LayoutContext)

  const isInLeadGen = fixedPathname !== location.pathname
  const showLeadGenFullscreen =
    isInLeadGen && typeof window !== `undefined` && window.innerWidth < 600

  useEffect(() => {
    setLayout(state => ({
      ...state,
      hide: showLeadGenFullscreen,
      header: {},
    }))
    return function unmount() {
      setLayout(state => ({
        ...state,
        hide: false,
        header: {},
      }))
    }
  }, [])

  const head = (
    <HelmetHead
      title="Was ist Ihre Immobilie wert? Immobilienbewertung vom Fachmann"
      description="Wir ermitteln den optimalen Preis für Ihre Immobilie. Eine gründliche Immobilienbewertung ist unverzichtbar für den erfolgreichen Verkauf Ihrer Immobilie."
      keywords="Immobilienbewertung, Wertermittlung Immobilie, Immobilienwert"
      links={[
        {
          rel: "canonical",
          href: `${process.env.GATSBY_SITE_URL}${canonicalPathname}`,
        },
      ]}
    />
  )

  return showLeadGenFullscreen ? (
    <>
      {head}
      <LeadGeneratorFullscreen fixedPathname={fixedPathname} />
    </>
  ) : (
    <>
      {head}
      <BackgroundSection src="haus_dunkler3.jpg" className="pt-5 bg-cover">
        <div className="container-fluid">
          <div className="row align-items-center" style={{ minHeight: "90vh" }}>
            <div className="col-12">
              <div
                className="card card-bleed mx-md-auto shadow-light-lg mt-10"
                style={{ minHeight: "495px", maxWidth: "767px" }}
              >
                <LeadGeneratorLoadable
                  leadGenerator="rating"
                  setPath={fixedPathname}
                />
              </div>
            </div>
          </div>
        </div>
      </BackgroundSection>

      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
          <Curve1 />
        </div>
      </div>

      <section className="py-7 py-md-9 border-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="scroll-beacon" id="immobilienbewertung-intro" />
              <a
                href="#immobilienbewertung-intro"
                className="btn btn-white btn-rounded-circle shadow mt-n11 mt-md-n13"
                onClick={smoothScroll}
              >
                <ArrowDown size={20} />
              </a>
            </div>
          </div>

          <div className="scroll-beacon" id="so-funktionierts" />
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <h2 className="fw-bold">Immobilienbewertung</h2>
              <h3 className="text-muted mb-9 mb-md-11">
                in 3 einfachen Schritten
              </h3>
            </div>
          </div>
          <div className="row g-0">
            <div className="col-12 col-md-4">
              <div className="row mb-5 justify-content-center">
                <div className="col d-none d-lg-block" />
                <div className="col-auto mt-8 mt-md-0">
                  <StepImgNo
                    src="ImmobilieGartenCenter.jpg"
                    alt="Haus - Daten Immobilie"
                    no={1}
                    dataSal="slide-down"
                    dataSalDelay="200"
                    dataSalDuration="700"
                  />
                </div>
                <div className="col d-none d-lg-block">
                  <hr className="border-primary-desat" />
                </div>
              </div>
              <h3 className="fw-bold px-2">Daten zu Ihrer Immobilie</h3>
              <p className="text-muted mb-6 mb-md-0 px-2">
                In wenigen Schritten geben Sie die wichtigsten Daten zu Ihrer
                Immobilie in das intuitive Formular ein. Jede Angabe zu Ihrem
                Objekt sorgt für ein besseres Ergebnis. Die Nutzung der
                Wert­ermittlung ist in jedem Fall kostenfrei und unverbindlich.
              </p>
            </div>
            <div className="col-12 col-md-4">
              <div className="row mb-5 justify-content-center">
                <div className="col d-none d-lg-block">
                  <hr className="border-primary-desat" />
                </div>
                <div className="col-auto mt-8 mt-md-0">
                  <StepImgNo
                    src="Fotolia_61667417_S.jpg"
                    alt="Berechnung Immobilienwert"
                    no={2}
                    dataSal="slide-down"
                    dataSalDelay="500"
                    dataSalDuration="700"
                  />
                </div>
                <div className="col d-none d-lg-block">
                  <hr className="border-primary-desat" />
                </div>
              </div>
              <h3 className="fw-bold px-2">Analyse und Wertermittlung</h3>
              <p className="text-muted mb-6 mb-md-0 px-2">
                Ihre Anfrage wird individuell und mit größter Sorgfalt von
                ausgebildeten Fach­leuten ausgewertet. Die Lage ist der
                wichtigste Einfluss­faktor, aber bei weitem nicht der Einzige.
                Daher empfehlen wir einen Besichtigungs­termin mit einem unserer
                Immobilien­experten aus Ihrer Umgebung.
              </p>
            </div>
            <div className="col-12 col-md-4">
              <div className="row mb-5 justify-content-center">
                <div className="col d-none d-lg-block">
                  <hr className="border-primary-desat" />
                </div>
                <div className="col-auto mt-8 mt-md-0">
                  <StepImgNo
                    src="Energieausweis.jpg"
                    alt="Wertermittlung Immobilie - Preis"
                    no={3}
                    dataSal="slide-down"
                    dataSalDelay="800"
                    dataSalDuration="700"
                  />
                </div>
                <div className="col d-none d-lg-block" />
              </div>
              <h3 className="fw-bold px-2">Konkretes Ergebnis</h3>
              <p className="text-muted mb-0 px-2">
                Sie erhalten innerhalb von 24 Stunden eine erste
                Markt­einschätzung. Auf Wunsch ermitteln wir vor Ort den genauen
                Wert gemäß der gesetzlich normierten
                Immobilien-Wert­ermittlungs­verordnung (ImmoWertV) nach § 194
                Baugesetz.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-8 py-md-11 bg-gray-200 border-bottom">
        <div className="container">
          <div className="row justify-content-center mb-7 mb-md-9">
            <div className="col-12 col-md-10">
              <h2 className="fw-bold">
                Gute Gründe für eine Immobilenbewertung
              </h2>
              <p className="fs-lg text-muted">
                Eine zuverlässige Wertermittlung ist{" "}
                <b>unverzichtbar für den erfolgreichen Verkauf</b> Ihrer
                Immobilie. Sie sollten hier nichts dem Zufall überlassen und
                sich gründlich informieren.
              </p>

              <p className="fs-lg text-muted">
                Mithilfe einer unverbindlichen Immobilienbewertung erhalten Sie
                eine <b>zuverlässige Grundlage</b> für Ihre Entscheidungen und
                Verhandlungsgespräche mit Miteigentümern und potenziellen
                Käufern.
              </p>
            </div>
          </div>

          <div className="row justify-content-center mb-5">
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <h3 className="fw-bold">Ihre Vorteile</h3>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-12 col-md-6 col-lg-4">
              <div
                className="icon mb-4 text-center text-primary-desat"
                data-sal="slide-down"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                <TimeSchedule />
              </div>
              <h4 className="fw-bold text-center">
                Ersparnis von Zeit und Geld
              </h4>
              <p className="text-muted">
                Ein Wertgutachten ist häufig mit einem hohen Kosten- und
                Zeitaufwand verbunden. Mit der kostenfreien Immobilienbewertung
                verschaffen Sie sich einen zuverlässigen Überblick.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-4 order-md-2 order-lg-1">
              <div
                className="icon mb-4 text-center text-primary-desat"
                data-sal="slide-down"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                <Euro />
              </div>
              <h4 className="fw-bold text-center">
                Sorgfältige Wertermittlung
              </h4>
              <p className="text-muted">
                Profitieren Sie von unserem Erfahrungs­schatz und unserer großen
                Datenbank an Vergleichsobjekten in Ihrer Region. Wir beauftragen
                einen Makler aus Ihrer Region mit der kostenfreien und
                unverbindlichen Bewertung Ihrer Immobilie.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-4 order-md-1 order-lg-2">
              <div
                className="icon mb-4 text-center text-primary-desat"
                data-sal="slide-down"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                <ClipboardCheck />
              </div>
              <h4 className="fw-bold text-center">Exklusiver Mehrwert</h4>
              <p className="text-muted">
                Auf Wunsch erhalten Sie neben dem Immobilienwert eine
                hochwertige Marktanalyse, inklusive aller in die Berechnung mit
                einfließenden Daten.
              </p>
            </div>
          </div>

          <div className="row justify-content-center mt-5 mt-md-6">
            <div
              className="col-12 col-md-7 col-lg-6"
              data-sal="fade"
              data-sal-delay="200"
              data-sal-duration="700"
            >
              <Link
                to="/de/maklerempfehlung/"
                className="btn btn-block btn-primary d-flex align-items-center lift"
              >
                Finden Sie jetzt den richtigen Makler{" "}
                <ArrowRight size={15} className="ms-auto" />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="py-8 py-md-11 border-bottom">
        <div className="container">
          <h2 className="text-center">
            In welchen Fällen eine{" "}
            <span className="text-primary">Immobilienbewertung</span> sinnvoll
            ist
          </h2>
          <div className="row justify-content-center pt-5 mb-7 mb-md-9">
            <div className="col-12 col-md-6 d-flex">
              <div className="icon text-primary">
                <Wallet />
              </div>
              <div className="ms-5">
                <h4 className="mb-1">VERKAUF</h4>
                <p className="text-muted mb-6">
                  Privatpersonen sowie gewerbliche Anbieter schaffen damit die
                  Grundlage für Preisverhandlungen und einen erfolgreichen
                  Verkauf.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex">
              <div className="icon text-primary-desat">
                <Wallet />
              </div>
              <div className="ms-5">
                <h4 className="mb-1">KAUF</h4>
                <p className="text-muted mb-6">
                  Auch als potenzieller Käufer, sollten Sie sich für
                  Preisverhandlungen über den Wert der Immobilie vorab
                  informieren.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex">
              <div className="icon text-primary">
                <CodeGithub />
              </div>
              <div className="ms-5">
                <h4 className="mb-1">ERBSCHAFT</h4>
                <p className="text-muted mb-6">
                  Im Falle von Erbstreitigkeiten bzw. der{" "}
                  <a
                    href="https://www.steuerklassen.com/erbschaftssteuer/"
                    target="_blank"
                    className="text-muted"
                  >
                    Besteuerung einer Erbschaft
                  </a>{" "}
                  dient die Immobilienbewertung als eine wertvolle Grundlage.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex">
              <div className="icon text-primary-desat">
                <CodeGithub />
              </div>
              <div className="ms-5">
                <h4 className="mb-1">SCHEIDUNGEN</h4>
                <p className="text-muted mb-6">
                  Bei einer Scheidung sollten Sie das Wertgutachten der
                  Immobilie zur Ermittlung des{" "}
                  <a
                    href="https://www.finanztip.de/zugewinnausgleich/"
                    target="_blank"
                    className="text-muted"
                  >
                    Zugewinns
                  </a>{" "}
                  während der Ehezeit hinzuziehen.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex">
              <div className="icon text-primary">
                <Home />
              </div>
              <div className="ms-5">
                <h4 className="mb-1">UMBAU</h4>
                <p className="text-muted mb-6">
                  Die Wertsteigerung Ihrer Immobilie im Zuge eines Aus- oder
                  Umbaus, lässt sich durch eine Immobilienbewertung leicht
                  ermitteln.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex">
              <div className="icon text-primary-desat">
                <Home />
              </div>
              <div className="ms-5">
                <h4 className="mb-1">WOHNGEBÄUDEVERSICHERUNG</h4>
                <p className="text-muted mb-6">
                  Auch zur Überprüfung der{" "}
                  <a
                    href="https://de.wikipedia.org/wiki/Wohngeb%C3%A4udeversicherung"
                    target="_blank"
                    className="text-muted"
                  >
                    Wohngebäudeversicherung
                  </a>{" "}
                  benötigen Sie eine aktuelle Werteinschätzung Ihrer Immobilie.
                </p>
              </div>
            </div>
          </div>

          <CTA className="mb-n14" />
        </div>
      </section>

      <section className="pt-14 pb-8 pb-md-11 bg-gray-200 border-bottom">
        <div className="container">
          <div className="scroll-beacon" id="checkliste-immobilienbewertung" />
          <h2 className="text-center">
            Checkliste für die Immobilienbewertung
          </h2>

          <h3 className="h4 fw-bold mb-5">
            Seien Sie optimal vorbereitet und halten Sie die folgenden Daten für
            die Immobilienbewertung bereit:
          </h3>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Adress-Details (Straße, Hausnummer, PLZ, Ort)
                </p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Baujahr der Immobilie (auch ein Zeitraum möglich)
                </p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">Zimmeranzahl</p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">Wohnfläche (in m²)</p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Grundstücksfläche (in m²) bei Häusern bzw. Etage bei Wohnungen
                </p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Angaben zu Balkon / Garten / Aufzug
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">Parkmöglichkeiten</p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">Zustand von Mauerwerk und Dach</p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Angaben zur Dämmung, Fenstern, Bodenbelag, Heizung,
                  Sanitäranlagen
                </p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">Besondere Ausstattungsmerkmale</p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Angaben zur Lage: Ausblick, Wohnumfeld, Besonnung,
                  Raumaufteilung
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="py-8 py-md-11 border-bottom bg-between"
        style={{
          backgroundImage: `url(${imgLeft}), url(${imgRight})`,
          backgroundPosition: "left bottom, right bottom",
          backgroundSize: "auto 70%",
        }}
      >
        <div className="container">
          <h2 className="text-center">
            So wird der Wert einer Immobilie ermittelt
          </h2>

          <p className="fs-lg text-gray-500">
            Anhand der Checkliste können Sie bereits erkennen, welche Vielzahl
            an Objektdaten zur Bewertung einer Immobilie hinzugezogen wird. Je
            nach Art der Immobilie können dann verschiedene Verfahren zur
            Wertermittlung zum Einsatz kommen:
          </p>

          <div
            id="wertverfahren"
            className="card-group card-border card-border-lg border-primary shadow-light-lg"
          >
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col"> </th>
                        <th scope="col">
                          <h3>Vergleichswertverfahren</h3>
                        </th>
                        <th scope="col">
                          <h3>Sachwertverfahren</h3>
                        </th>
                        <th scope="col">
                          <h3>Ertragswertverfahren</h3>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Einsatz</th>
                        <td>Insbesondere bei Eigentumswohnungen</td>
                        <td>
                          Bei Ein- und Zweifamilienhäusern (teilweise auch
                          Eigentumswohnungen)
                        </td>
                        <td>
                          Mietimmobilien (insbesondere. Mehrfamilienhäuser)
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Verfahren</th>
                        <td>
                          Ableitung von tatsächlich realisierten Kaufpreisen
                          ähnlicher Immobilien. Im Optimalfall gelingt der
                          direkte Vergleich mit einem benachbarten Objekt mit
                          vergleichbaren Objektkriterien.
                        </td>
                        <td>
                          Der Wert bemisst sich nach den Herstellungs- bzw.
                          Wieder­beschaffungs­kosten (Sachwert) unter
                          Berücksichtigung aller angegebenen Objekt-Daten.
                        </td>
                        <td>
                          Die Wertermittlung setzt sich zusammen aus den Kosten
                          für Grund und Boden und den dauerhaft erzielbaren
                          Erträgen (z.B. Mieterträge) der Immobilie.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-8 py-md-11 bg-gray-200 border-bottom">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-6 mb-7 mb-lg-0">
              <h2 className="fw-bold">
                Wann eine Immobilienbewertung vom Gutachter sinnvoll ist
              </h2>

              <p className="fs-lg text-muted">
                Die <b>Immobilienbewertung</b> vom Fachmann ist sehr präzise und
                basiert auf allgemein anerkannte Verfahren der Wertermittlung.
                Sie können daher auf eine sehr hohe Genauigkeit bei der
                Wertermittlung vertrauen. Dennoch kann die Wertermittlung
                selbstverständlich nicht ganz mit den Ergebnissen eines
                erfahrenen Gutachters vor Ort mithalten.
              </p>
              <p className="fs-lg text-muted">
                Sehen Sie unseren <b>Service als eine Chance</b> an, sich
                schnell und kostenfrei einen ersten Eindruck vom Wert Ihrer
                Immobilie zu verschaffen, um bei bevorstehenden Verhandlungen
                nicht im Dunkeln zu tappen.
              </p>
              <p className="fs-lg text-muted">
                Ein zusätzliches Gutachten vor Ort wird Ihnen letztendlich die{" "}
                <b>nötige Ergebnissicherheit</b> liefern. Und nicht nur Ihnen,
                auch Ihren potenziellen Käufern. Denn ein qualifizierter
                Gutachter handelt stets als neutraler und unabhängiger Experte.
              </p>
            </div>
            <div className="col-12 col-lg-6">
              <p className="fs-lg fw-bold text-center">
                Zum Mehrwert eines Gutachters zählt außerdem:
              </p>
              <div className="card card-border border-success shadow-lg mb-5">
                <div className="card-body">
                  <div className="list-group list-group-flush">
                    <div className="list-group-item d-flex align-items-center text-secondary">
                      <div className="me-auto">
                        <p className="fw-bold mb-1">
                          Die Beurteilung des baulichen und technischen Zustands
                        </p>
                      </div>
                    </div>
                    <div className="list-group-item d-flex align-items-center text-secondary">
                      <div className="me-auto">
                        <p className="fw-bold mb-1">
                          Eine noch genauere Einschätzung von Lage und
                          Umweltfaktoren
                        </p>
                      </div>
                    </div>
                    <div className="list-group-item d-flex align-items-center text-secondary">
                      <div className="me-auto">
                        <p className="fw-bold mb-1">
                          Die Berücksichtigung des{" "}
                          <a
                            href="https://de.wikipedia.org/wiki/Erschlie%C3%9Fung_(Grundst%C3%BCck)"
                            target="_blank"
                          >
                            Erschließungsgrads
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="list-group-item d-flex align-items-center text-secondary">
                      <div className="me-auto">
                        <p className="fw-bold mb-1">
                          Eine Berechnung des genauen{" "}
                          <a
                            href="https://de.wikipedia.org/wiki/Energieverbrauchskennwert"
                            target="_blank"
                          >
                            Energiekennwerts
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="list-group-item d-flex align-items-center text-secondary">
                      <div className="me-auto">
                        <p className="fw-bold mb-1">
                          Die Miteinbeziehung der Grundstückslasten
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CTA2>
        <blockquote className="blockquote">
          <p className="h2 text-white">Wir sind da wenn Sie uns brauchen.</p>
        </blockquote>
      </CTA2>

      <section className="py-8 py-md-11">
        <div className="container">
          <h2 className="text-center">Glossar zur Immobilienbewertung</h2>

          <Accordion
            defaultActiveKey="informationen-immobilienbewertung"
            className="card shadow-light-lg mt-7 mt-md-9 mb-5 mb-md-6"
          >
            <Accordion.Item eventKey="informationen-immobilienbewertung">
              <AccordionHeaderCustomScroll anchor="informationen-immobilienbewertung">
                Welche Informationen liefert eine Immobilienbewertung?
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <p className="text-muted">
                  Neben dem durchschnittlich gerundeten Marktwert Ihrer
                  Immobilie, enthalten die Ergebnisse außerdem, …
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        …den durchschnittlichen Wert pro m² Wohnfläche
                      </p>
                    </div>
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        …ermittelte Schwankung des Wertes
                      </p>
                    </div>
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">…Wertspanne pro m²</p>
                    </div>
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        …durchschnittliche Mietpreise für Ihr Objekt
                      </p>
                    </div>
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        …Wertermittlung individueller Ergänzungen
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">…Immobilienindex</p>
                    </div>
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        …Regionale Kaufpreisinformation
                      </p>
                    </div>
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">…Wohnumfeld-Informationen</p>
                    </div>
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">…Erläuterungen</p>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="immobilienbewertung-sinnvoll">
              <AccordionHeaderCustomScroll anchor="immobilienbewertung-sinnvoll">
                Wann ist eine Immobilienbewertung sinnvoll?
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <p className="text-muted">
                  Als Verkäufer und Käufer einer Immobilie ist es wertvoll, eine
                  kostenlose Wertermittlung in Anspruch zu nehmen. Beide Seiten
                  schaffen damit eine Grundlage für weitere Preisverhandlungen.
                </p>
                <p className="text-muted">
                  Weitere Fälle, wann die Bewertung einer Immobilie sinnvoll
                  ist:
                </p>
                <ul className="text-muted">
                  <li className="my-1">
                    Bei Erbschaft zur Ermittlung der Besteuerung
                  </li>
                  <li className="my-1">
                    Bei Scheidungen zur Ermittlung des Zugewinns
                  </li>
                  <li className="my-1">
                    Bei Um- oder Ausbau zur Ermittlung der Wertsteigerung
                  </li>
                  <li className="my-1">
                    Zur Überprüfung der Wohngebäudeversicherung
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="dauer-immobilienbewertung">
              <AccordionHeaderCustomScroll anchor="dauer-immobilienbewertung">
                Wie lange dauert die Immobilienbewertung?
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <p className="text-muted">
                  Unser Formular leitet Sie in wenigen Minuten intuitiv und
                  einfach durch die Dateneingabe. Bereits wenige Momente später
                  erhalten Sie unsere ausführlichen Ergebnisse per Mail.
                </p>
                <p className="text-muted">
                  Um sich optimal vorzubereiten, nutzen Sie auch unsere kurze{" "}
                  <u>
                    <a
                      href="#checkliste-immobilienbewertung"
                      onClick={smoothScroll}
                    >
                      Checkliste
                    </a>
                  </u>
                  , um alle notwendigen Daten direkt griffbereit zu haben.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="vorteile-gegen-gutacher">
              <AccordionHeaderCustomScroll anchor="vorteile-gegen-gutacher">
                Welche Informationen liefert eine Immobilienbewertung?
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <div className="d-flex">
                  <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                    <Check size={15} />
                  </div>
                  <p className="text-muted">
                    Kostenlos (ein Gutachter nimmt i.d.R 1-1,5 % des
                    Immobilienwerts)
                  </p>
                </div>
                <div className="d-flex">
                  <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                    <Check size={15} />
                  </div>
                  <p className="text-muted">
                    Schnell ermittelt (in wenigen Tagen)
                  </p>
                </div>
                <div className="d-flex">
                  <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                    <Check size={15} />
                  </div>
                  <p className="text-muted">
                    Unverbindlich (für einen ersten Einblick)
                  </p>
                </div>
                <p className="text-muted">
                  Je nachdem mit welcher Absicht Sie eine Immobilie bewerten
                  lassen möchten, ist es jedoch sehr sinnvoll, zusätzlich einen
                  professionellen Gutachter einzuschalten. Sie erhalten dadurch
                  den offiziellen Verkehrswert.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="berechnung-immobilienwert">
              <AccordionHeaderCustomScroll anchor="berechnung-immobilienwert">
                Welche Informationen liefert eine Immobilienbewertung?
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <p className="text-muted">
                  Wir verfügen über eine große Datenbank an Vergleichsobjekten
                  in ganz Deutschland. Diese umfängliche Datenbank ermöglicht
                  uns eine sehr präzisere Wertermittlung jedweder Immobilie. Wir
                  gleichen dabei die angegebenen Objektdaten mit passenden
                  Immobilien (vergleichbare Daten) in der Region ab. Folgende
                  Kriterien spielen dabei u.a. eine Rolle:
                </p>
                <ul className="text-muted">
                  <li className="my-1">Objektdetails</li>
                  <li className="my-1">Ausstattung / Sonderausstattung</li>
                  <li className="my-1">
                    Bauzustand (Berücksichtigung aller Ausbauten und
                    Modernisierungsmaßnahmen)
                  </li>
                  <li className="my-1">Individuelle Objektmerkmale</li>
                  <li className="my-1">Lage und Umwelt der Immobilie</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </section>

      <section>
        <div className="container" id="newsletter">
          <div className="pb-6 pb-md-8 border-bottom border-gray-300">
            <Newsletter />
          </div>
        </div>
      </section>
    </>
  )
}

export default Immobilienbewertung
